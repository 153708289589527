<template>
  <div>
    <!-- 顶部 -->
    <div class="top" :class="flag ? '' : 'navTop'" v-if="college !=='d9cdydbdh'">
      <div class="nav-lt">
        <div class="top-left lf">
          <a href="https://www.bisu.edu.cn/" target="_blank">
            <img src="../assets/img/second/2012300126354659905.png" v-if="flag" />
            <img src="../assets/img/second/2012300152177864819(1).png" v-else />
          </a>
        </div>
        <div class="names" :class="flag ? '' : 'navName'" @click="go" style="cursor:pointer;" v-html="str2name[college] || '学院'">
  <!--        &nbsp;&nbsp;&nbsp;&nbsp;文化与传播学院-->
  <!--        {{str2name[college] || '学院'}}-->
        </div>
      </div>
      <div class="nav-rt">
        <ul class="link">
          <li
            :class="navLiClass(item)"
            v-for="(item, index) in navList"
            :key="index"
            @mouseover="show(index)"
            @mouseleave="hide(index)"
          >

            <router-link v-if="item.title === 'HOME' " class="ellipsis" :to="item.link">
              <div class="nameBottom" :class="flag ? '' : 'navNameBottom'">
                {{ item.name }}
              </div>
              <div class="icons" :class="flag ? '' : 'navIcons'"></div>
            </router-link>
            <span class="ellipsis" v-else-if="item.title==='translate'" @click="translatePage(item)" >
              <div class="nameBottom" :class="flag ? '' : 'navNameBottom'">
                {{ item.name }}
              </div>
              <div class="icons" :class="flag ? '' : 'navIcons'"></div>
            </span>
            <span class="ellipsis" v-else @click="jumpNav(item)" >
              <div class="nameBottom" :class="flag ? '' : 'navNameBottom'">
                {{ item.name }}
              </div>
              <div class="icons" :class="flag ? '' : 'navIcons'"></div>
            </span>
            <div
              v-if="item.children && item.children.length > 0"
              :class="index == activeIdx ? 'show linkMenu' : 'hide linkMenu'"
              :style="{ left: -32  + 'px', 'backgroundColor': flag ? '#6b6262' : '#fff', 'color': flag ? '':'rgb(107, 98, 98)'  }"
            >
              <div class="menus">
                <span v-for="(i, index) in item.children" :key="i.name" @click="jump(i,item, index)">{{
                  i.name
                }}</span>
              </div>
            </div>
          </li>
        </ul>
        <img class="search" src="../assets/img/search.png" alt="" v-if="flag" style="position: relative; top: 0.20833vw;"/>
        <img class="search" src="../assets/img/redSearch.png" alt="" v-else style="position: relative; top: 0.20833vw;"/>
      </div>
    </div>
    <div class="top1" v-else>
      <div class="nav">
        <ul>
          <li
      
            v-for="(item, index) in navList"
            :key="index"
        
          >
          <router-link v-if="item.title === 'HOME' || !item.children || (item.children && !item.children.length)" class="ellipsis" :to="item.link">
              <div class="nameBottom nameBottomNew" :class="flag ? '' : 'navNameBottom'">
                {{ item.name }}
              </div>
              <div class="icons" :class="flag ? '' : 'navIcons'"></div>
            </router-link>
            <span class="ellipsis" v-else >
              <div class="nameBottom nameBottomNew" :class="flag ? '' : 'navNameBottom'">
                {{ item.name }}
              </div>
              <div class="icons" :class="flag ? '' : 'navIcons'"></div>
            </span>
            <div
              v-if="item.children && item.children.length > 0"
              :class="index == activeIdx ? 'show linkMenu' : 'hide linkMenu'"
              :style="{ left: -32  + 'px', 'backgroundColor': flag ? '#6b6262' : '#fff', 'color': flag ? '':'rgb(107, 98, 98)'  }"
            >
              <div class="menus">
                <span v-for="(i, index) in item.children" :key="i.name" @click="jump(i,item, index)">{{
                  i.name
                }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  
</template>

<script>
import Velocity from "velocity-animate";
import {allCollegeInfo} from "../allCollegeInfo";
export default {
  inject: ["reload"],
  data() {
    return {
      searchInput: "",
      navBarStyle: {
        background: "#58098b",
      },
      isRotated: false,
      pathname: "",
      path: "",
      treelist: [],
      flag: true,
      activeIdx: null,
      scroll: "",
      clientHeight: "",
      colors: "first", //first选中第一层，second选中第二层
      dialogVisible: false,
      navList: [
        {
          id: "1",
          name: "首页",
          title: "HOME",
          link: "/home",
          children: [],
        },
        {
          id: "2",
          name: "学院概况",
          title: "RECRUIT STUDENTS",
          link: "",
          children: [
            {
              name: "学院简介",
              id: this.$columnIdFile["学院简介"],
              type: "detail",
            },
            {
              name: "现任领导",
              id: this.$columnIdFile["现任领导"],
              type: "detail",
            },
            {
              name: "院长致辞",
              id: this.$columnIdFile["院长致辞"],
              type: "detail",
            },
            {
              name: "学院荣誉",
              id: this.$columnIdFile["学院荣誉"],
              type: "detail",
            },
            {
              name: "联系我们",
              id: this.$columnIdFile["联系我们"],
              type: "detail",
            },
          ],
        },
        {
          id: "3",
          name: "师资队伍",
          title: "COURSE",
          link: {
            path: "/teachers",
            query: {
              columnId: this.$columnIdFile['szdw'],
              // title: "师资队伍",
              id: 0
            },
          },
          // children: []
          children: [
            { name: "汉语言文学系", id: this.$columnIdFile["汉语言文学系"], type: "teachers" },
            { name: "汉语国际教育系", id: this.$columnIdFile["汉语国际教育系"], type: "teachers" },
            { name: "新闻系", id: this.$columnIdFile["新闻系"], type: "teachers" },
            { name: "专家教授", id: this.$columnIdFile["专家教授"], type: "teachers" },
            { name: "客座教授", id: this.$columnIdFile["客座教授"], type: "teachers" },
            { name: "党政教辅", id: this.$columnIdFile["党政教辅"], type: "teachers" },
            // {
            //   name: "汉语国际教育系",
            //   id: this.$columnIdFile["汉语国际教育系"],
            //   type: "list",
            // },
            // { name: "新闻系", id: this.$columnIdFile["新闻系"], type: "list" },
            // {
            //   name: "专家教授",
            //   id: this.$columnIdFile["专家教授"],
            //   type: "list",
            // },
            // {
            //   name: "客座教授",
            //   id: this.$columnIdFile["客座教授"],
            //   type: "list",
            // },
            // {
            //   name: "党政教辅",
            //   id: this.$columnIdFile["党政教辅"],
            //   type: "detail",
            // },
          ],
        },
        {
          id: "4",
          name: "学术研究",
          title: "LEARNING LIFE",
          link: "",
          children: [
            {
              name: "科研项目",
              id: this.$columnIdFile["科研项目"],
              type: "list",
            },
            {
              name: "科研成果",
              id: this.$columnIdFile["科研成果"],
              type: "list",
            },
            {
              name: "学术交流",
              id: this.$columnIdFile["学术交流"],
              type: "list",
            },
          ],
        },
        {
          id: "7",
          name: "人才培养",
          title: "LEARNING LIFE",
          link: "",
          children: [
            {
              name: "本科生培养",
              id: this.$columnIdFile["本科生培养"],
              type: "list",
            },
            {
              name: "研究生培养",
              id: this.$columnIdFile["研究生培养"],
              type: "list",
            },
          ],
        },
        {
          id: "5",
          name: "国际交流",
          title: "SCHOLARSHIP",
          link: "",
          children: [
            {
              name: "合作机构",
              id: this.$columnIdFile["合作机构"],
              type: "list",
            },
            {
              name: "项目介绍",
              id: this.$columnIdFile["项目介绍"],
              type: "list",
            },
            {
              name: "域外采风",
              id: this.$columnIdFile["域外采风"],
              type: "list",
            },
          ],
        },
        {
          id: "6",
          name: "学生工作",
          title: "REGISTRATION ENTRANCE",
          link: "",
          children: [
            {
              name: "学生组织",
              id: this.$columnIdFile["学生组织"],
              type: "list",
            },
            {
              name: "规章制度",
              id: this.$columnIdFile["规章制度"],
              type: "list",
            },
            {
              name: "学生活动",
              id: this.$columnIdFile["学生活动"],
              type: "list",
            },
          ],
        },
        {
          id: "8",
          name: "表格下载",
          link: {
            path: "/newsTrends",
            query: {
              columnId: this.$columnIdFile['表格下载'],
              title: "表格下载",
            },
          },
          children: [
            {
              name: "教师使用",
              id: this.$columnIdFile["教师使用"],
              type: "list",
            },
            {
              name: "学生使用",
              id: this.$columnIdFile["学生使用"],
              type: "list",
            },
          ]
        },
      ],
      college: this.$store.state.college,
      str2name: this.$store.state.str2name,
      Carousel:true,
    };
  },
  watch: {
    $route: {
      handler: function (to) {
        this.path = to.path;
        if (
          this.path.includes("newsTrends") ||
          this.path.includes("newsDetail")
        ) {
          this.navBarStyle.background = "#58098b";
        } else {
          this.navBarStyle.background = "transparent";
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.college !== 'whycbxy' && this.college !== 'zgggzcfyyjy') {
      if (!allCollegeInfo(this)[this.college].header?.Carousel) {
        this.Carousel = allCollegeInfo(this)[this.college].header.Carousel;
        this.flag = this.Carousel
      }
      this.navList = allCollegeInfo(this)[this.college].header.navList;
    }
    if (this.college === 'zgggzcfyyjy') {
      this.Carousel = true
      this.flag = this.Carousel
      const language = localStorage.getItem('language')
      const collegeName = language ==='en' ? this.college + 'en' : this.college
      this.navList = allCollegeInfo(this)[collegeName].header.navList;
    }
    localStorage.setItem('navList', JSON.stringify(this.navList));
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.clientHeight = document.documentElement.clientHeight;

    this.pathname = window.location.pathname;

    // this.$eventBus.$emit('navList', '888');
  },
  methods: {
    navLiClass(item) {
      let className = ''
      if (this.filterName(item)) {
        className += this.activeIndex
      }
      className += this.college
      return className
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0 || !this.Carousel) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    handleVeforeEnter: (el) => {
      el.style.opacity = 0;
    },
    handleEnter: (el, done) => {
      Velocity(
        el,
        {
          opacity: 1,
        },
        {
          duration: 100,
          complete: done,
        }
      );
    },
    handleAfterEnter: () => {
      // console.log('动画结束')
    },
    mouseenter() {
      // this.ewImgIndex = index
    },
    mouseleave() {
      // this.ewImgIndex = 0
    },

    filterName(item) {
      let flag = false;
      if (item.link == this.path) {
        this.colors = "first";
        flag = true;
      }
      if (!flag && item.children && item.children.length > 0) {
        item.children.forEach(async (its) => {
          if (its.link == this.path) {
            this.colors = "second";
            flag = true;
            return;
          }
        });
      }
      return flag;
    },
    jumpNav(item){
      this.$router.push({
        path:item.link.path,
        query:item.link.query
      })
    },
    translatePage() {
      const language = localStorage.getItem('language')
      localStorage.setItem('language', language === 'cn' ? 'en':'cn')
      this.$router.push({
        path:'/home',
        query:{}
      })
      this.$router.go(0);
    },
    jumpNavlist(link, idx) {
      // debugger;
      // item.tab;

      this.$router.push({
        path: link,
        query: {
          id: idx,
        },
      });
      // this.reload();
    },

    show(idx) {
      this.activeIdx = idx;
      this.isRotated = true;
    },
    hide() {
      this.activeIdx = null;
      this.isRotated = false;
    },
    jump(i,item, index) {
      console.log(i.link)
      console.log(i.query)
      if (i.type === "detail") {
        this.$router.push({
          name: "newsDetail",
          params: {
            columnId: i.id,
            id: "list",
          },
        });
      } else if (i.type === "list") {
        this.$router.push({
          name: "newsTrends",
          query: {
            columnId: i.id,
            title: i.name,
            tab:item.id,
            hide: "hide"
          },
        });
      } else if (i.type === "teachers") {
        if (i.name === '师德师风') {
          window.open('http://shide.bisu.edu.cn');
          return;
        }
        if (i.name === '教师发展') {
          window.open('http://cfd.bisu.edu.cn/');
          return;
        }
        this.$router.push({
          path: "/teachers",
          query: {
            columnId: i.id,
            title: i.name,
            id: index,
            hide: "hide",
            columnName: i.columnName || '师资队伍',
            // tab:item.id
          },
        });
      }else if(i.link){
        this.$router.push({
        path: i.link.path,
        query: i.link.query
      });
      }
    },
    go() {
      this.$router.push('/home')
    },

  },
};
</script>

<style lang="scss" scoped>
.show {
  display: block;
}
.hide {
  display: none;
}
.linkMenu {
  padding: 20px 0;
  width: 140px;
  position: absolute;
  background-color: #6b6262;
  top: 100%;
  border-radius: 10px;
  .menus {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    &>span {
      width: 100%;
      text-align: center;
    }
  }

  // left: -400%;
  // background: #fff url("../assets/img/ernavBg.png") center bottom no-repeat;
  // background-size: contain;
}
.gotoTop {
  position: fixed;
  right: 362px;
  bottom: 379px;
  width: 90px;
  height: 90px;
  background: #ffffff;
  border: 4px solid #58098b;
  z-index: 9999;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  .tri {
    width: 33px;
    display: inline;
    margin-top: 15px;
  }
  .topText {
    font-family: "STSongti-SC, STSongti-SC";
    font-weight: bold;
    font-size: 28px;
    color: #58098b;
    line-height: 20px;
    font-style: normal;
  }
}
.top {
  padding: 0 60px 0 67px;
  width: 100%;
  height: 141px;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 1999;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  color: #ebebeb;
  font-weight: 400;
  font-size: 18px;
  // position: fixed;
  justify-content: space-between;
  // top: 0;
  //z-index: 999;
  background: url("../assets/img/navBg1.png") ;
  background-size: 100% 100%;
}
.navTop {
  background: #fff;
}
.nav-lt {
  display: flex;
  // width: 100%;
  align-items: center;
  .lf {
    margin-right: 19px;
    img {
      width: 324px;
      height: 68px;
    }
  }
  .names {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    padding-left: 18px;
    height: 45px;

    font-weight: 400;
    font-size: 22px;
    color: #ebebeb;
  }
  .navName {
    border-left: 1px solid rgba(161, 41, 22);
    color: rgb(161, 41, 22);
  }
}

.top-nav li {
  cursor: pointer;
}

.nav-rt,
.nav-rt ul {
  display: flex;
  align-items: center;
}
.nav-rt .search {
  width: 32px;
  margin-left: 12px;
  cursor: pointer;
}
.nav-rt ul li {
  position: relative;
  list-style: none;
  margin-right: 30px;
  width: auto;
  text-align: left;
  cursor: pointer;
  padding: 14px 0 0;

  &.activeIndex {
    .icons {
      background: #fff;
    }
    .navIcons {
      background: rgb(161, 41, 22);
    }
  }
  &:hover {
    .icons {
      background: #fff;
    }
  }
  .icons {
    margin-top: 18px;
    width: 100%;
    height: 2px;
    background: transparent;
    border-radius: 1px;
  }
  .nameBottom {
    height: 18px;

    font-weight: 400;
    font-size: 18px;
    color: #ebebeb;
  }
  
  .navNameBottom {
    color: rgb(161, 41, 22);
  }
}

.nav-rt ul .lykxxy {
  margin-left: 20px;
  margin-right: 0;
}
/* 下面我们会解释这些 class 是做什么的 */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.link > li > a.hover:before {
  transform: none;
}
.link > li > a.hover:after {
  transform: none;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 20px 10px;
}
.notice_ctx {
  font-size: 16px;
  text-indent: 2em;
  margin: 5px 0;
  line-height: 180%;
  color: #444;
  a {
    text-decoration: underline;
    font-size: 16px;
    font-family: 微软雅黑;
    color: #a63829;
  }
}
.hint-in3a {
  font-size: 14px;
  color: #8c0000;
  line-height: 30px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.top1{
  width: 100%;
  height: 290px;
  padding-top: 233px;
  background: url("../assets/img/d9cdydbh.jpg") top no-repeat;
  background-size: 100% 100%;
}
.nav{
  width: 1070px;
  height: 57px;
  line-height: 57px;
  margin: 0 auto;
  text-align: left;
}
.nav ul{
  display: flex;
  flex-direction: row;
  align-items: center;
    height: 100%;
    justify-content: center;
}
.nameBottomNew{
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
    margin: 0 35px;
  }
</style>
