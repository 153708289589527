import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowDetail:false,
    columnList:[],
    pageJson: {},
    college: '',
    str2name: {
      whycbxy: '文化与传播学院',
      gjjyxy: '国际教育学院（国际培训学院）',
      mkszyxy: '马克思主义学院',
      zdwjxy: '政党外交学院',
      jjxy: '经济学院',
      yzxy: '亚洲学院',
      zdxy: '中东学院',
      ryxy: 'bat·365(中文)官方网站-登录入口',
      hyxy: '汉语学院',
      lykxxy: '旅游科学学院',
      // 10 ========
      yyxy: '英语学院<br/> School of English Studies',
      jx60zn: '建校60周年专题网站',
      MIB: '经济学院MIB',
      jckxb: '基础科学部',
      tyb: '体育部',
      sxy: '商学院',
      gjfyxy: '高级翻译学院',
      d9cdydbdh: '第九次党员代表大会',
      ozxy: '欧洲学院',
      szwl: '数字文旅研究中心',
      sxymba: '商学院MBA',
      zrhhzyjzx:'中日韩合作研究中心',
      bjlyfzyjzx:'北京旅游发展研究基地',
      blyjzx:'秘鲁研究中心',
      dmyjzx:'丹麦研究中心',
      zglyrcfzyjy:'中国旅游人才发展研究院',
      rgznyyyrz:'中国人工智能与语言认知实验室',
      zgwhhlycy:'中国文化和旅游产业研究院',
      zgwhhlydsj:'中国文化和旅游大数据研究院',
      zgggzcfyyjy:'中国公共政策翻译研究院',
    }
  },
  mutations: {
    changeShowDetail(state,val){
      console.log("changeShowDetail",val);
      state.isShowDetail = val;
    },
    setColumnList(state,val){
      state.columnList = val;
    },
    setPageJson(state, val) {
      state.pageJson = val;
    },
    setCollege(state, val) {
      state.college = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
